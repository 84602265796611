import React from "react";
import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import carouselImage1 from "../assets/images/action.png";
import carouselImage2 from "../assets/images/action2.png";
import carouselImage3 from "../assets/images/action3.png";
import carouselImage4 from "../assets/images/action4.png";

const carouselItems = [
  { image: carouselImage1 },
  { image: carouselImage2 },
  { image: carouselImage3 },
  { image: carouselImage4 }
];

const CarouselComponent = () => {
  return (
    <Box

    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible
        navButtonsProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "50%",
          },
        }}
        NextIcon={<ArrowForwardIos />}
        PrevIcon={<ArrowBackIos />}
      >
        {carouselItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden", 
            }}
          >
            <img
              src={item.image}
              alt={`Carousel ${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", 
              }}
            />
            <Typography
              variant="h4"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#FFFFFF",
                zIndex: 1,
                textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)", 
                fontSize: { xs: "1.2rem", md: "2rem" }, 
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarouselComponent;
