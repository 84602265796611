import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import axios from "axios";
import goldTrophy from "../assets/images/trophy1.png";
import silverTrophy from "../assets/images/trophy2.png";
import bronzeTrophy from "../assets/images/trophy3.png";

const Podium = () => {
  const [topUsers, setTopUsers] = useState([
    { NAMES: "N/A", POINTS: 0 },
    { NAMES: "N/A", POINTS: 0 },
    { NAMES: "N/A", POINTS: 0 },
  ]);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        // Realizar la solicitud sin requerir el token
        const response = await axios.get(
          "https://api.master-bikas.com/api/top-points"
        );

        setTopUsers(response.data);
      } catch (error) {
        console.error("Error al obtener los usuarios con más puntos:", error);
      }
    };

    fetchTopUsers();
  }, []);

  return (
    <Box sx={{ textAlign: "center", mt: 4, px: { xs: 2, md: 4 } }}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 3, md: 4 },
          color: "#0cc0df",
          fontWeight: "bold",
          fontSize: { xs: "1.8rem", md: "2.5rem" },
        }}
      >
        RANKING
      </Typography>

      {/* Contenedor del Podio */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-around", 
          gap: { xs: 6, md: 4 },
          minHeight: { xs: "auto", md: "500px" }, 
          width: "100%",
          maxWidth: "1200px", 
          margin: "0 auto",
          px: { md: 8 }, 
        }}
      >
        {/* Segundo Lugar */}
        <Box
          sx={{
            order: { xs: 2, md: 1 },
            transform: { xs: "none", md: "translateY(50px)" },
            width: { md: "30%" },
          }}
        >
          <img
            src={silverTrophy}
            alt="Segundo Lugar"
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "150px", 
              transition: "transform 0.3s ease",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              color: "#C0C0C0",
              fontWeight: "bold",
              fontSize: { xs: "1.1rem", md: "1.5rem" },
            }}
          >
            {topUsers[1]?.NAMES || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontSize: { xs: "0.9rem", md: "1.2rem" },
            }}
          >
            {topUsers[1]?.POINTS || 0} puntos
          </Typography>
        </Box>

        {/* Primer Lugar */}
        <Box
          sx={{
            order: { xs: 1, md: 2 },
            transform: { xs: "none", md: "translateY(-20px)" },
            borderRadius: "16px",
            p: 4, 
            mb: { xs: 2, md: 0 },
            width: { md: "40%" }, 
          }}
        >
          <img
            src={goldTrophy}
            alt="Primer Lugar"
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "180px",
              transition: "transform 0.3s ease",
            }}
          />
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              color: "#FFD700",
              fontWeight: "bold",
              fontSize: { xs: "1.3rem", md: "1.8rem" },
            }}
          >
            {topUsers[0]?.NAMES || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontSize: { xs: "1rem", md: "1.4rem" },
            }}
          >
            {topUsers[0]?.POINTS || 0} puntos
          </Typography>
        </Box>

        {/* Tercer Lugar */}
        <Box
          sx={{
            order: { xs: 3, md: 3 },
            transform: { xs: "none", md: "translateY(70px)" },
            width: { md: "30%" }, 
          }}
        >
          <img
            src={bronzeTrophy}
            alt="Tercer Lugar"
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "140px", 
              transition: "transform 0.3s ease",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              color: "#CD7F32",
              fontWeight: "bold",
              fontSize: { xs: "1rem", md: "1.4rem" },
            }}
          >
            {topUsers[2]?.NAMES || "N/A"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontSize: { xs: "0.9rem", md: "1.1rem" },
            }}
          >
            {topUsers[2]?.POINTS || 0} puntos
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Podium;
