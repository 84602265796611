import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  TextField,
  Box,
  IconButton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const PaymentPanel = () => {
  const [payments, setPayments] = useState([]);
  const [editPaymentId, setEditPaymentId] = useState(null);
  const [editStatus, setEditStatus] = useState("");
  const [editAmount, setEditAmount] = useState("");

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No se encontró un token, omitiendo fetchPayments.");
      return;
    }

    try {
      const response = await axios.get("https://api.master-bikas.com/api/payments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPayments(response.data);
    } catch (error) {
      console.error("Error al obtener los pagos:", error);
      if (error.response?.status === 403) {
        alert(
          "No tienes los permisos necesarios para acceder a esta información."
        );
      }
    }
  };

  const handleEditClick = (payment) => {
    setEditPaymentId(payment.id);
    setEditStatus(payment.status);
    setEditAmount(payment.amount);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm("¿Estás seguro de eliminar este pago?")) {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error(
          "No se encontró un token, omitiendo la solicitud de eliminación."
        );
        return;
      }

      try {
        await axios.delete(`https://api.master-bikas.com/api/payments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchPayments();
      } catch (error) {
        console.error("Error al eliminar el pago:", error);
        if (error.response?.status === 403) {
          alert("No tienes permisos para eliminar este pago.");
        } else {
          alert("Error al eliminar el pago. Por favor, intenta de nuevo.");
        }
      }
    }
  };

  const handleSaveClick = async (id) => {
    if (!["PENDIENTE", "PAGADO", "CANCELADO", "DEVOLUCION"].includes(editStatus)) {
      alert(
        "Estado no válido. Solo se permite PENDIENTE, PAGADO, CANCELADO o DEVOLUCION."
      );
      return;
    }
  
    if (!editAmount || isNaN(editAmount) || parseFloat(editAmount) < 0) {
      alert("Monto inválido. Debe ser un número positivo.");
      return;
    }
  
    const token = localStorage.getItem("token"); 
  
    if (!token) {
      console.error("No se encontró un token.");
      return;
    }
  
    try {
      await axios.put(
        `https://api.master-bikas.com/api/payments/${id}`,
        {
          status: editStatus,
          amount: parseFloat(editAmount).toFixed(3),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      fetchPayments(); 
      setEditPaymentId(null); 
    } catch (error) {
      console.error("Error al guardar el pago:", error);
      alert("Error al guardar el pago. Por favor, inténtalo de nuevo.");
    }
  };
  

  const handleCancelClick = () => {
    setEditPaymentId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <main className="flex-1 p-4 bg-[#FCFBFC]" style={{ marginTop: "50px" }}>
      <Container>
        <Box mt={4}>
          <Card>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Monto</TableCell>
                    <TableCell align="right">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>{payment.id}</TableCell>
                        <TableCell>{payment.email}</TableCell>
                        <TableCell>
                          {editPaymentId === payment.id ? (
                            <TextField
                              value={editStatus}
                              onChange={(e) => setEditStatus(e.target.value)}
                              select
                              SelectProps={{ native: true }}
                            >
                              <option value="PENDIENTE">Pendiente</option>
                              <option value="PAGADO">Pagado</option>
                              <option value="CANCELADO">Cancelado</option>
                              <option value="DEVOLUCION">Devolución</option>
                            </TextField>
                          ) : (
                            payment.status
                          )}
                        </TableCell>
                        <TableCell>
                          {editPaymentId === payment.id ? (
                            <TextField
                              value={editAmount}
                              onChange={(e) => setEditAmount(e.target.value)}
                              type="number"
                            />
                          ) : payment.amount !== null &&
                            payment.amount !== undefined ? (
                            parseFloat(payment.amount).toFixed(2)
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {editPaymentId === payment.id ? (
                            <>
                              <Button
                                onClick={() => handleSaveClick(payment.id)}
                              >
                                Guardar
                              </Button>
                              <Button onClick={handleCancelClick}>
                                Cancelar
                              </Button>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => handleEditClick(payment)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteClick(payment.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Paginador */}
            <TablePagination
              component="div"
              count={payments.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}
            />
          </Card>
        </Box>
      </Container>
    </main>
  );
};

export default PaymentPanel;
