import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Publicidad from "./Publicidad";
import Testimonials from "./Testimonials";
import Podium from "./Podium";
import Navbar from "./Navbar";
import CarouselComponent from "./CarouselComponent";
import Footer from "./Footer";

const services = [
  {
    title: "Videos de Matemáticas",
    description:
      "¡Aprender matemáticas nunca fue tan entretenido! Descubre videos interactivos y dinámicos en nuestro TikTok @MasterBikas, donde hacemos que los conceptos más complejos sean fáciles y divertidos de entender. ¡Síguenos y disfruta aprendiendo!",
    icon: "📹",
  },
  {
    title: "Tutoría de Matemáticas",
    description:
      "Envía tus preguntas y recibe soluciones personalizadas explicadas paso a paso. Nuestros tutores expertos se enfocan en tus necesidades para que aprendas de manera rápida y efectiva. Aprende a tu ritmo y alcanza tus objetivos con nuestro apoyo personalizado.",
    icon: "📘",
  },
  {
    title: "Recursos de Matemáticas",
    description:
      "Encuentra respuestas detalladas a tus dudas, participa en preguntas semanales y acumula puntos para ganar increíbles premios. Aprende mientras te diviertes con recursos diseñados especialmente para que domines las matemáticas y seas recompensado por tu esfuerzo.",
    icon: "📚",
  },
];

const LandingPage = () => {
  return (
    <Box sx={{ bgcolor: "#FEFEFE", minHeight: "100vh" }}>
      <Navbar />

      {/* Carousel */}
      <Box sx={{ width: "100%", height: "auto", p: 0 }}>
        <CarouselComponent />
      </Box>

      {/* Quiénes somos */}
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Box sx={{ mt: { xs: 5, md: 10 }, textAlign: "center" }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <video
                controls
                width="100%"
                style={{ borderRadius: "8px" }}
                src="ruta/del/video.mp4"
              ></video>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{
                  mb: 2,
                  color: "#0cc0df",
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", md: "2rem" },
                }}
              >
                ¿Quiénes somos?
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  fontSize: { xs: "0.9rem", md: "1rem" },
                }}
              >
                Impulsamos el aprendizaje en todo el Perú, ayudando a
                estudiantes a resolver dudas, fortalecer sus conocimientos y
                alcanzar sus metas académicas con herramientas personalizadas,
                preguntas desafiantes y recompensas motivadoras.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Servicios */}
        <Box sx={{ mt: { xs: 5, md: 10 }, textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              color: "#0cc0df",
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
          >
            NUESTROS SERVICIOS
          </Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    p: { xs: 2, md: 3 },
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        mb: 2,
                        fontSize: { xs: "1rem", md: "1.25rem" },
                      }}
                    >
                      {service.icon} {service.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{
                        fontSize: { xs: "0.85rem", md: "1rem" },
                        textAlign: "center",
                      }}
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      {/* Publicidad */}
      <Publicidad />

      {/* Testimonios */}
      <Testimonials />

      <Box sx={{ mt: 8, textAlign: "center", color: "#306D90", p: 4 }}>
        <Podium />
      </Box>

      <Footer />
    </Box>
  );
};

export default LandingPage;
