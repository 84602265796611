import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Rating,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Flecha personalizada "Siguiente"
const CustomNextArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      right: "-20px",
      transform: "translateY(-50%)",
      zIndex: 2,
      backgroundColor: "#0cc0df",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#0a9bb3",
      },
    }}
  >
    <ArrowForwardIosIcon />
  </IconButton>
);

// Flecha personalizada "Anterior"
const CustomPrevArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      left: "-20px",
      transform: "translateY(-50%)",
      zIndex: 2,
      backgroundColor: "#0cc0df",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#0a9bb3",
      },
    }}
  >
    <ArrowBackIosIcon />
  </IconButton>
);

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.master-bikas.com/api/testimonials"
        );
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error al obtener los testimonios:", error);
      }
    };
    fetchTestimonials();
  }, []);

  const sliderSettings = {
    infinite: true, // Ciclo continuo
    speed: 500,
    slidesToShow: 3, // Mostrar 3 testimonios
    slidesToScroll: 1, // Moverse de uno en uno
    arrows: true, // Mostrar flechas de navegación
    nextArrow: <CustomNextArrow />, // Flecha personalizada
    prevArrow: <CustomPrevArrow />, // Flecha personalizada
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2, // Mostrar 2 testimonios en pantallas medianas
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Mostrar 1 testimonio en pantallas pequeñas
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container maxWidth="lg" sx={{ p: 0 }}>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h4"
          sx={{ mb: 4, textAlign: "center", color: "#0cc0df", fontWeight: "bold" }}
        >
          TESTIMONIOS
        </Typography>
        <Slider {...sliderSettings} style={{ padding: "0 24px" }}>
          {testimonials.map((testimonial) => (
            <Box key={testimonial.TESTIMONIAL_ID} sx={{ p: 2 }}>
              <Card
                sx={{
                  minHeight: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: 3,
                  borderRadius: "10px",
                  overflow: "hidden",
                  mr: 5,
                }}
              >
                <CardContent
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ mt: 2, fontWeight: "bold", color: "#0cc0df" }}
                  >
                    {testimonial.user_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "#666",
                      textAlign: "center",
                      lineHeight: "1.6",
                    }}
                  >
                    {testimonial.CONTENT}
                  </Typography>
                </CardContent>
                <Box sx={{ mt: 2, textAlign: "center", pb: 2 }}>
                  <Rating
                    name="rating"
                    value={testimonial.RATING}
                    readOnly
                    precision={0.5}
                  />
                </Box>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  );
};

export default Testimonials;
