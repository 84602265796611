import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import plinLogo from "../assets/images/plinLogo.jpeg";
import yapeLogo from "../assets/images/yapeLogo.jpeg";
import monto from "../assets/images/monto.jpeg";
import descripcion from "../assets/images/descripcion.jpeg";
import QRCode from "../assets/images/QR.jpeg";
import QRCode2 from "../assets/images/QR2.jpeg";

const PaymentSteps = () => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box sx={{ mt: 6, textAlign: "center" }}>
      <Typography variant="h5" sx={{ color: "#0cc0df", mb: 2 }}>
        Cómo realizar el pago:
      </Typography>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: "#666" }}>
            Paso 1: Abre tu Aplicativo Yape o Plin
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <img
              src={yapeLogo}
              alt="Logo Yape"
              style={{ width: "150px", height: "auto", borderRadius: "8px" }}
            />
            <img
              src={plinLogo}
              alt="Logo Plin"
              style={{ width: "150px", height: "auto", borderRadius: "8px" }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: "#666" }}>
            Paso 2: Escanea el código QR
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <img
              src={QRCode}
              alt="Código QR Yape"
              style={{ width: "150px", height: "150px" }}
            />
            <img
              src={QRCode2}
              alt="Código QR Plin"
              style={{ width: "150px", height: "150px" }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: "#666" }}>
            Paso 3: Digita el monto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img
            src={monto}
            alt="Paso 3"
            style={{ width: "100%", maxWidth: "300px", marginBottom: "20px" }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: "#666" }}>
            Paso 4 (Opcional): Digita “Pago por solución”
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img
            src={descripcion}
            alt="Paso 4"
            style={{ width: "100%", maxWidth: "300px", marginBottom: "20px" }}
          />
        </AccordionDetails>
      </Accordion>

      <Typography variant="h6" sx={{ mt: 3, color: "#0cc0df" }}>
        ¡Sigue estos pasos y disfruta de tus soluciones altoqueson!
      </Typography>
    </Box>
  );
};

export default PaymentSteps;
