import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ isAdminRoute }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    alert("Debes registrarte para acceder a nuestros servicios.");
    return <Navigate to="/register" replace />; 
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      alert("Tu sesión ha expirado. Por favor, inicia sesión nuevamente.");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      return <Navigate to="/login" replace />;
    }

    if (isAdminRoute && !decodedToken.isAdmin) {
      alert("No tienes permiso para acceder a esta página.");
      return <Navigate to="/" replace />;
    }
  } catch (error) {
    console.error("Error al decodificar el token:", error);
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
