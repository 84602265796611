import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { TextField, Button, Typography, Box, Alert } from "@mui/material";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();

  const handleResetPassword = async () => {
    const token = searchParams.get("token"); 

    if (!token) {
      setError("El enlace no es válido o ha expirado");
      return;
    }

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    try {
      setError(""); // Limpiar errores previos
      setMessage(""); // Limpiar mensajes previos

      const response = await axios.post(
        "https://api.master-bikas.com/api/reset-password",
        { password, token }
      );
      setMessage(response.data.message);
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "Error al restablecer la contraseña, intenta nuevamente"
      );
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "0 auto",
        textAlign: "center",
        padding: "20px",
        boxShadow: 3,
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
        Restablecer contraseña
      </Typography>
      <TextField
        label="Nueva contraseña"
        type="password"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Confirmar nueva contraseña"
        type="password"
        fullWidth
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        sx={{ mb: 2 }}
      />
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {message && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleResetPassword}
        fullWidth
      >
        Restablecer contraseña
      </Button>
    </Box>
  );
};

export default ResetPassword;
