import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { CSVLink } from "react-csv";
import Navbar2 from "./Navbar2";
import axios from "axios";

const SemanalesPanel = () => {
  const [answers, setAnswers] = useState([]);

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const csvHeaders = [
    { label: "ID", key: "ID" },
    { label: "Nombres y Apellidos", key: "FULL_NAME" },
    { label: "Email", key: "EMAIL" },
    { label: "Celular", key: "TELEPHONE" },
    { label: "Pregunta Imagen", key: "QUESTION_IMAGE" },
    { label: "Solución de los usuarios", key: "SOLUTION_URL" },
  ];

  useEffect(() => {
    fetchWeeklyAnswers();
  }, []);

  const fetchWeeklyAnswers = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get("https://api.master-bikas.com/api/weekly-answers", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAnswers(response.data);
    } catch (error) {
      console.error("Error al obtener las respuestas semanales:", error);
    }
  };

  const handleAssignPoints = (id) => {
    console.log(`Asignar puntos para la respuesta con ID: ${id}`);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="flex flex-col w-full min-h-screen">
      <Navbar2 />
      <main className="flex-1 p-4 bg-[#FCFBFC]" style={{ marginTop: "50px" }}>
        <Container>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <CSVLink
              headers={csvHeaders}
              data={answers}
              filename="preguntas_semanales.csv"
              className="btn btn-primary"
              style={{
                textDecoration: "none",
                color: "white",
                backgroundColor: "#007bff",
                padding: "10px 20px",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              Descargar CSV
            </CSVLink>
          </Box>
          <Box mt={4}>
            <Card>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nombres y Apellidos</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Celular</TableCell>
                      <TableCell>Pregunta</TableCell>
                      <TableCell>Solución de los usuarios</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {answers.map((answer) => (
                      <TableRow key={answer.ID}>
                        <TableCell>{answer.ID}</TableCell>
                        <TableCell>{answer.FULL_NAME}</TableCell>
                        <TableCell>{answer.EMAIL}</TableCell>
                        <TableCell>
                          {answer.TELEPHONE || "No registrado"}
                        </TableCell>
                        <TableCell>
                          <img
                            src={answer.QUESTION_IMAGE}
                            alt="Pregunta"
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <a
                            href={answer.SOLUTION_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver solución
                          </a>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAssignPoints(answer.ID)}
                          >
                            Asignar puntos
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Paginador */}
              <TablePagination
                component="div"
                count={answers.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
              />
            </Card>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default SemanalesPanel;
