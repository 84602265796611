import React from "react";
import { Box, Typography } from "@mui/material";

const ImportantInstructions = () => {
  return (
    <Box sx={{ my: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: "8px" }}>
      <Typography variant="h6" gutterBottom>
        Consideraciones Generales
      </Typography>
      <Typography variant="body2" gutterBottom>
        - El stock de los premios se ACTUALIZA la primera semana de cada mes.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Solo se puede reclamar 1 premio por USUARIO.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Los puntos del usuario SE REINICIAN la primera semana del mes
        siguiente.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - No se permite plagio ni el uso de múltiples cuentas para participar.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Los PREMIOS serán entregados los días sábados o domingos en
        coordinación con el usuario GANADOR.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Si el usuario es menor de edad, deberá brindar un correo electrónico o
        número de teléfono de su padre, madre o tutor legal.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Se verificará la autenticidad de los datos proporcionados por el
        usuario GANADOR antes de proceder con la entrega del premio.
      </Typography>
    </Box>

    
  );
};

export default ImportantInstructions;
