import React from "react";
import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

const ImportantInstructions = () => {
  return (
    <Box sx={{ my: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: "8px" }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign:"center"}}>
        Importante
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Asegúrate de realizar el pago correctamente utilizando el código QR proporcionado.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Solo presiona “PAGO REALIZADO” si el pago ha sido completado.
      </Typography>
      <Typography variant="body2" gutterBottom>
        - Si encuentras problemas con el pago o el acceso a la respuesta, contáctanos inmediatamente a través de nuestro soporte técnico: soporte.masterbikas@gmail.com o a través de nuestro WhatsApp: 921 346 549.
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ color: "#fd0000"}}>
        - En caso de que el botón “PAGO REALIZADO" sea presionado sin haber realizado el pago, el sistema lo detectará y se bloqueará su cuenta temporalmente.
      </Typography>
    </Box>
  );
};

export default ImportantInstructions;
