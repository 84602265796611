import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const AwardsDialog = ({ open, handleClose, success }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {success === "Premio reclamado exitosamente."
          ? "FELICIDADES"
          : "BIKEASTE"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {success === "Premio reclamado exitosamente."
            ? "Tu premio será entregado el día sábado o domingo vía WhatsApp o correo."
            : success || "Hubo un error al procesar tu solicitud."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default AwardsDialog;
