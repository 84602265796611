import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from "@mui/material";
import logo from "../assets/images/logo.png";
import osoIcon from "../assets/images/oso.png";
import axios from "axios";

const Navbar2 = () => {
  const [username, setUsername] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [points, setPoints] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedToken = localStorage.getItem("token");

    console.log("Username almacenado en localStorage:", storedUsername);
    console.log("Token almacenado en localStorage:", storedToken);

    if (storedUsername) {
      setUsername(storedUsername);
    }

    // Función para obtener los puntos del usuario
    const fetchPoints = async () => {
      try {
        const response = await axios.get("https://api.master-bikas.com/api/points", {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        console.log("Respuesta obtenida de /api/points:", response.data);
        setPoints(response.data.points);
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        console.log("Detalles del error:", error.response?.data);
      }
    };

    if (storedToken) {
      fetchPoints();
    } else {
      console.log(
        "No se encontró token en localStorage, omitiendo fetchPoints."
      );
    }
  }, []);

  // Manejar el cierre de sesión
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.master-bikas.com/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event) => {
    setDropdownAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: "#0cc0df" }}>
      <Toolbar>
        <Link to="/admin" style={{ textDecoration: "none" }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              marginRight: 20,
              width: "50px",
              height: "50px",
              cursor: "pointer",
            }}
          />
        </Link>

        <Typography
          variant="h6"
          sx={{ flexGrow: 1, fontWeight: "bold", color: "#ffffff" }}
        ></Typography>
        <nav>
          <Box
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
            sx={{ display: "inline-block", position: "relative" }}
          >
            <Button
              color="inherit"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
              component={Link}
              to="/admin/personalizadas"
            >
              Preguntas Personalizadas
            </Button>
            <Button
              color="inherit"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
              component={Link}
              to="/admin/semanales"
            >
              Preguntas Semanales
            </Button>
            <Button
              color="inherit"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
              component={Link}
              to="/admin/coursePanel"
            >
              Cursos
            </Button>
            <Button
              color="inherit"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
              component={Link}
              to="/admin/publicidad"
            >
              publicidad
            </Button>
            <Button
              color="inherit"
              sx={{ fontWeight: "bold", color: "#ffffff" }}
              component={Link}
              to="/admin/testimonios"
            >
              Testimonios
            </Button>
          </Box>
        </nav>
        <Typography
          sx={{ color: "#ffffff", fontWeight: "bold", marginRight: "10px" }}
        >
          Hola, {username}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleMenuClick}>
            <Avatar src={osoIcon} alt="Oso" />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => navigate("/user-profile")}>
            Mi Perfil
          </MenuItem>
          <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar2;
