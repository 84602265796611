import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";

const VerifyEmail = () => {
  const [message, setMessage] = useState("Verificando tu correo...");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");

      if (!token) {
        setMessage("Token inválido. No se puede verificar el correo.");
        return;
      }

      try {
        const response = await axios.get(`https://api.master-bikas.com/api/verify-email?token=${token}`);
        setMessage("Correo verificado exitosamente.");
        setTimeout(() => navigate("/login"), 3000); 
      } catch (error) {
        console.error("Error al verificar el correo:", error);
        setMessage(
          error.response?.data?.error || "Error al verificar el correo. Por favor, inténtalo de nuevo."
        );
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        px: 2,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        {message}
      </Typography>
      {message === "Verificando tu correo..." && <CircularProgress />}
    </Box>
  );
};

export default VerifyEmail;
