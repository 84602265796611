import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import ZoomableImageModal from "./ZoomableImageModal";
import bikeando from "../assets/images/Bikeanding.png";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SocialMedia from "./SocialMedia";

const QuestionDetail = () => {
  const { courseId, topicId, questionId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [question, setQuestion] = useState([]);
  const [answer, setAnswer] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [answerLink, setAnswerLink] = useState("");
  const [currentTopic, setCurrentTopic] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  console.log(
    "Course ID:",
    courseId,
    "Topic ID:",
    topicId,
    "Question ID:",
    questionId
  );

  useEffect(() => {
    const fetchPaidAnswers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const response = await axios.get(
          "https://api.master-bikas.com/api/paid-answers",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 200) {
          const answersMap = {};
          response.data.forEach((answer) => {
            answersMap[answer.QUESTION_ID] = {
              link: answer.LINK,
              isLocked: false,
            };
          });
          setAnswer((prev) => ({ ...prev, ...answersMap }));
        }
      } catch (error) {
        console.error("Error al obtener respuestas pagadas:", error);
      }
    };

    fetchPaidAnswers();
  }, []);

  useEffect(() => {
    const fetchCurrentTopic = async () => {
      try {
        const response = await axios.get(
          `https://api.master-bikas.com/api/courses/${courseId}/topics`
        );

        const selectedTopic = response.data.find(
          (topic) => topic.id === parseInt(topicId)
        );

        if (selectedTopic) {
          setCurrentTopic(selectedTopic);
        } else {
          console.error("No se encontró el tema seleccionado.");
        }
      } catch (error) {
        console.error("Error al obtener el tema actual:", error);
      }
    };

    fetchCurrentTopic();
  }, [courseId, topicId]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const questionResponse = await axios.get(
          `https://api.master-bikas.com/api/topics/${topicId}/questions`
        );

        console.log("Respuesta de preguntas:", questionResponse.data);
        setQuestion(questionResponse.data);
        setFilteredQuestion(questionResponse.data);

        const answersMap = {};

        questionResponse.data.forEach((q) => {
          answersMap[q.id] = { link: null, isLocked: true };
        });

        setAnswer(answersMap);

        const token = localStorage.getItem("token");
        if (token) {
          const attemptsResponse = await axios.get(
            "https://api.master-bikas.com/api/attempts",
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setAttempts(attemptsResponse.data.remaining_attempts);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.error("No se encontraron preguntas o estado de pago.");
        } else {
          console.error("Error al cargar los datos iniciales:", error);
        }
      }
    };

    fetchInitialData();
  }, [topicId]);

  const unlockWithAttempt = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Por favor, inicia sesión para desbloquear esta respuesta.");
        return;
      }

      const response = await axios.post(
        `https://api.master-bikas.com/api/attempts/unlock/${questionId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAnswer((prev) => ({
          ...prev,
          [questionId]: { link: response.data.answerLink, isLocked: false },
        }));
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error al desbloquear con intento:", error);
      alert(
        error.response?.data?.message ||
          "Hubo un problema al intentar desbloquear la respuesta."
      );
    }
  };

  useEffect(() => {
    const results = question.filter((q) =>
      q.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestion(results);
  }, [searchTerm, question]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/pago", { state: { questionId } });
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <Box sx={{ bgcolor: "#FEFEFE", minHeight: "100vh" }}>
      <Navbar />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            position: "relative",
            gap: 2,
          }}
        >
          <Box sx={{ width: "35%" }} />

          {currentTopic ? (
            <Typography
              sx={{
                flex: 1,
                textAlign: "center",
                color: "#0cc0df",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", md: "2rem" },
              }}
            >
              {currentTopic.name}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{
                flex: 1,
                textAlign: "center",
                color: "gray",
                fontSize: "0.9rem",
              }}
            >
              No se encontró el tema seleccionado.
            </Typography>
          )}

          {/* Buscador a la derecha */}
          <Box sx={{ width: "30%" }}>
            <TextField
              label="Buscar pregunta"
              variant="outlined"
              placeholder="Pregunta 1"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
            />
          </Box>
        </Box>
      </Container>
      <Grid container spacing={4} sx={{ py: 5 }}>
        {/* Columna 1: Redes Sociales */}
        <Grid
          item
          xs={6}
          md={3.8}
          sx={{
            bgcolor: "white",
            borderRadius: 2,
          }}
        >
          <SocialMedia />
        </Grid>

        {/* Columna 2: */}

        <Grid
          item
          xs={6}
          md={5}
          sx={{
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          {Array.isArray(filteredQuestion) && filteredQuestion.length > 0 ? (
            filteredQuestion.map((item, index) => (
              <Box key={index}>
                {/* Título de la Pregunta */}
                <Typography
                  variant="h4"
                  sx={{ my: 2, textAlign: "center", color: "#0cc0df" }}
                >
                  {`Pregunta ${index + 1}`}
                </Typography>

                {/* Puntos y Monto */}
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <strong>Puntos por compra: {item.points || "N/A"}</strong>
                  <strong> Monto: S/ {item.amount || "N/A"}</strong>
                </Typography>

                {/* Imagen de la Pregunta */}
                <div
                  style={{
                    width: "80%",
                    textAlign: "center",
                    maxHeight: "600px",
                  }}
                >
                  <img
                    src={item.image}
                    alt={`Pregunta ${index + 1}`}
                    style={{
                      width: "100%",
                      maxHeight: "500px",
                      objectFit: "contain",
                      transition: "filter 0.5s ease",
                      marginBottom: "35px",
                    }}
                  />
                </div>

                {/* Mostrar Respuesta */}
                {answer[item.id]?.isLocked ? (
                  <>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      La respuesta está bloqueada. Usa un intento para
                      desbloquearla.
                    </Typography>
                    {attempts > 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => unlockWithAttempt(item.id)}
                        sx={{ mt: 2 }}
                      >
                        Usar Intento
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate("/pago", {
                            state: {
                              questionId: item.id,
                              amount: item.amount,
                              topicId,
                              courseId,
                            },
                          })
                        }
                        sx={{ mt: 3, mb: 5 }}
                      >
                        Desbloquear Contenido
                      </Button>
                    )}
                  </>
                ) : answer[item.id]?.link ? (
                  <div style={{ width: "70%", margin: "0 auto" }}>
                    <Typography
                      variant="h6"
                      sx={{ mb: 2, textAlign: "center" }}
                    >
                      Respuesta:
                    </Typography>
                    <img
                      src={answer[item.id].link}
                      alt="Respuesta"
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(answer[item.id].link)}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "gray" }}
                  >
                    La respuesta no está disponible.
                  </Typography>
                )}
              </Box>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{ color: "red", textAlign: "center" }}
            >
              No hay preguntas disponibles.
            </Typography>
          )}
          {/* Modal para Mostrar la Imagen */}
          <ZoomableImageModal
            isModalOpen={isModalOpen}
            handleCloseModal={handleCloseModal}
            currentImage={currentImage}
          />

          {/* Diálogo de Intentos Restantes */}
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Sin intentos restantes</DialogTitle>
            <DialogContent>
              <Typography>
                No tienes intentos restantes. Serás redirigido a la página de
                pago para realizar el pago correspondiente.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="primary"
                variant="contained"
              >
                Ir al Pago
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        {/* Columna 3: */}
        <Grid
          item
          xs={6}
          md={2.8}
          sx={{
            bgcolor: "white",
            borderRadius: 2,
            pl: 2,
          }}
        >
          {" "}
          <img src={bikeando} alt="Bikeando" style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default QuestionDetail;
