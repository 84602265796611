import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { CSVLink } from "react-csv";
import Navbar2 from "./Navbar2";
import axios from "axios";

const PersonalizadasPanel = () => {
  const [questions, setQuestions] = useState([]);

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const csvHeaders = [
    { label: "ID", key: "ID" },
    { label: "Email", key: "EMAIL" },
    { label: "Celular", key: "TELEPHONE" },
    { label: "Pregunta Imagen", key: "QUESTION" },
    { label: "Evidencia del Pago", key: "PAYMENT_EVIDENCE" },
    { label: "Whatsapp", key: "WHATSAPP" },
    { label: "Fecha de Creación", key: "CREATED_AT" },
  ];
  
  const fetchQuestions = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No se encontró un token, omitiendo fetchQuestions.");
      return;
    }

    try {
      const response = await axios.get(
        "https://api.master-bikas.com/api/preguntas-personalizadas",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Preguntas obtenidas:", response.data);
      setQuestions(response.data);
      console.log("Preguntas obtenidas desde la base de datos:", questions);
    } catch (error) {
      console.error("Error al obtener las preguntas personalizadas:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddSolution = (id) => {
    console.log(`Adjuntar solución para la pregunta con ID: ${id}`);
  };

  const handleEditSolution = (id) => {
    console.log(`Editar solución para la pregunta con ID: ${id}`);
  };

  const handleDeleteSolution = (id) => {
    if (window.confirm("¿Estás seguro de eliminar esta solución?")) {
      console.log(`Eliminar solución para la pregunta con ID: ${id}`);
    }
  };

  const handleViewSolution = (solutionUrl) => {
    window.open(solutionUrl, "_blank");
  };

  return (
    <div className="flex flex-col w-full min-h-screen">
      <Navbar2 />
      <main className="flex-1 p-4 bg-[#FCFBFC]" style={{ marginTop: "50px" }}>
        <Container>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <CSVLink
              headers={csvHeaders}
              data={questions}
              filename="preguntas_personalizadas.csv"
              className="btn btn-primary"
              style={{
                textDecoration: "none",
                color: "white",
                backgroundColor: "#007bff",
                padding: "10px 20px",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              Descargar CSV
            </CSVLink>
          </Box>
          <Box mt={4}>
            <Card>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Celular </TableCell>
                      <TableCell>Pregunta Imagen</TableCell>
                      <TableCell>Evidencia del Pago </TableCell>
                      <TableCell>Whatsapp </TableCell>
                      <TableCell>Solucion </TableCell>
                      <TableCell>Acciones </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((question) => (
                        <TableRow key={question.ID}>
                          <TableCell>{question.ID}</TableCell>
                          <TableCell>{question.EMAIL}</TableCell>
                          <TableCell>{question.TELEPHONE || "N/A"}</TableCell>
                          <TableCell>
                            {question.QUESTION ? (
                              <img
                                src={question.QUESTION}
                                alt={`Pregunta ${question.ID}`}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              "No disponible"
                            )}
                          </TableCell>
                          <TableCell>
                            {question.PAYMENT_EVIDENCE ? (
                              <img
                                src={question.PAYMENT_EVIDENCE}
                                alt={`Evidencia ${question.ID}`}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              "No disponible"
                            )}
                          </TableCell>
                          <TableCell>
                            {question.WHATSAPP ? "Sí" : "No"}
                          </TableCell>
                          <TableCell>
                            {question.SOLUTION ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleViewSolution(question.SOLUTION)
                                }
                              >
                                Ver solución
                              </Button>
                            ) : (
                              <span style={{ color: "red" }}>Pendiente</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleAddSolution(question.ID)}
                            >
                              Adjuntar solución
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleEditSolution(question.ID)}
                            >
                              Editar solución
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeleteSolution(question.ID)}
                            >
                              Eliminar solución
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Paginador */}
              <TablePagination
                component="div"
                count={questions.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
              />
            </Card>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default PersonalizadasPanel;
