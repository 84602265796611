import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TikTokIcon from "../assets/images/tiktok.png";

const SocialMedia = () => {
  const socialLinks = [
    {
      icon: <FacebookIcon fontSize="large" style={{ color: "#1877F2" }} />,
      text: "@Master Bikas",
      link: "https://www.facebook.com/profile.php?id=61566966383351",
    },
    {
      icon: <InstagramIcon fontSize="large" style={{ color: "#C13584" }} />,
      text: "@master.bikas",
      link: "https://www.instagram.com/master.bikas/",
    },
    {
      icon: <WhatsAppIcon fontSize="large" style={{ color: "#25D366" }} />,
      text: "+51 921 346 549",
      link: "https://wa.me/+51921346549?text=¡Hola!%20Estoy%20interesado%20en%20obtener%20más%20información.",
    },
    {
      icon: (
        <img
          src={TikTokIcon}
          alt="TikTok"
          style={{
            width: "32px",
            height: "32px",
            filter: "invert(1)",
          }}
        />
      ),
      text: "@master.bikas",
      link: "https://www.tiktok.com/@master.bikas",
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: 2,
        backgroundColor: "#f5f5f5",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        maxWidth: "300px",
        marginLeft:"80px"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          color: "#0cc0df",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        REDES SOCIALES
      </Typography>
      {socialLinks.map((social, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            padding: 1.5,
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#e6f7ff",
              transform: "scale(1.02)",
              transition: "0.2s",
            },
            mb: 1.5,
          }}
        >
          <IconButton
            onClick={() =>
              window.open(social.link, "_blank", "noopener,noreferrer")
            }
            sx={{
              backgroundColor: "#f9f9f9",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            {social.icon}
          </IconButton>
          <Typography
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontSize: "16px",
              flex: 1,
            }}
          >
            {social.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SocialMedia;
