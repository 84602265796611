import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Popper,
  Paper,
  Grow,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Divider,
} from "@mui/material";
import logo from "../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import osoIcon from "../assets/images/oso.png";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [username, setUsername] = useState("");
  const [points, setPoints] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedToken = localStorage.getItem("token");

    console.log("Username almacenado en localStorage:", storedUsername);
    console.log("Token almacenado en localStorage:", storedToken);

    if (storedUsername) {
      setUsername(storedUsername);
    }

    // Función para obtener los puntos del usuario
    const fetchPoints = async () => {
      try {
        const response = await axios.get("https://api.master-bikas.com/api/points", {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        console.log("Respuesta obtenida de /api/points:", response.data);
        setPoints(response.data.points);
      } catch (error) {
        console.error("Error al obtener los puntos:", error);
        console.log("Detalles del error:", error.response?.data);
      }
    };

    if (storedToken) {
      fetchPoints();
    } else {
      console.log(
        "No se encontró token en localStorage, omitiendo fetchPoints."
      );
    }
  }, []);

  // Manejar el cierre de sesión
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.master-bikas.com/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event) => {
    setDropdownAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  const token = localStorage.getItem("token");

  const isLoggedIn = token && username; 

  // Detectar clics fuera del menú para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDropdownClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <AppBar position="sticky" sx={{ bgcolor: "#0cc0df" }}>
      <Toolbar>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              marginRight: 20,
              marginTop: 5,
              width: "50px",
              height: "50px",
              cursor: "pointer",
            }}
          />
        </Link>

        <Typography
          variant="h6"
          sx={{ flexGrow: 1, fontWeight: "bold", color: "#ffffff" }}
        ></Typography>

        {/* Menú de Escritorio */}
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            color="inherit"
            sx={{ fontWeight: "bold", color: "#ffffff" }}
            component={Link}
            to="/"
          >
            Inicio
          </Button>
          <Button
            color="inherit"
            sx={{ fontWeight: "bold", color: "#ffffff" }}
            component={Link}
            to="/services"
          >
            Cursos
          </Button>
          <Box
            onMouseEnter={handleDropdownOpen}
            onMouseLeave={handleDropdownClose}
            sx={{ display: "inline-block", position: "relative" }}
          >
            <Button
              color="inherit"
              sx={{ color: "#ffffff", fontWeight: "bold" }}
              ref={(node) => {
                if (node && !dropdownAnchorEl) {
                  setDropdownAnchorEl(node);
                }
              }}
            >
              Preguntas
            </Button>
            <Popper
              open={dropdownOpen}
              anchorEl={dropdownAnchorEl}
              placement="bottom-start"
              transition
              sx={{ zIndex: 1300 }}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper
                    ref={dropdownRef}
                    sx={{ bgcolor: "#0cc0df", mt: 0.5, boxShadow: 3 }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/preguntas-personalizadas");
                        handleDropdownClose();
                      }}
                      sx={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        "&:hover": { bgcolor: "#3FD5EC" },
                      }}
                    >
                      Preguntas Personalizadas
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/preguntas-semanales");
                        handleDropdownClose();
                      }}
                      sx={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        "&:hover": { bgcolor: "#3FD5EC" },
                      }}
                    >
                      Preguntas Semanales
                    </MenuItem>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
          <Button
            color="inherit"
            sx={{ fontWeight: "bold", color: "#ffffff" }}
            component={Link}
            to="/about"
          >
            Sobre Nosotros
          </Button>
          <Button
            color="inherit"
            sx={{ fontWeight: "bold", color: "#ffffff" }}
            component={Link}
            to="/contact"
          >
            Contacto
          </Button>
        </Box>

        {/* Menú de Usuario */}
        {isLoggedIn ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: { xs: "16px", md: "24px" },
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Hola, {username}
            </Typography>
            <IconButton onClick={handleMenuClick}>
              <Avatar src={osoIcon} alt="Oso" />
            </IconButton>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              {points} pts
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => navigate("/user-profile")}>
                Mi Perfil
              </MenuItem>
              <MenuItem onClick={() => navigate("/awards")}>Premios</MenuItem>
              <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
            </Menu>
          </Box>
        ) : (
          <Button
            color="inherit"
            component={Link}
            to="/login"
            sx={{ color: "#FEFEFE" }}
          >
            INICIAR SESIÓN
          </Button>
        )}

        {/* Menú Hamburguesa */}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": { bgcolor: "#0cc0df", color: "#ffffff" },
          }}
        >
          <List>
            <ListItem button onClick={() => navigate("/")}>
              <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem button onClick={() => navigate("/services")}>
              <ListItemText primary="Servicios" />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate("/preguntas-personalizadas")}
            >
              <ListItemText primary="Preguntas Personalizadas" />
            </ListItem>
            <ListItem button onClick={() => navigate("/preguntas-semanales")}>
              <ListItemText primary="Preguntas Semanales" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => navigate("/about")}>
              <ListItemText primary="Sobre Nosotros" />
            </ListItem>
            <ListItem button onClick={() => navigate("/contact")}>
              <ListItemText primary="Contacto" />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
