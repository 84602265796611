import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Box, Button, Typography, Grid } from "@mui/material";
import Navbar from "./Navbar";

import QRCode from "../assets/images/QR.jpeg";
import QRCode2 from "../assets/images/QR2.jpeg";
import axios from "axios";
import PaymentSteps from "./PaymentSteps";
import ImportantInstructions from "./ImportantInstructions";
import Footer from "./Footer";

const PagoYape = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [timer, setTimer] = useState(300);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showWhatsAppButton, setShowWhatsAppButton] = useState(false);
  const [questionId, setQuestionId] = useState(
    location.state?.questionId || null
  );
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (!questionId) {
      alert("No se seleccionó ninguna pregunta.");
      navigate("/");
    }
  }, [questionId, navigate]);

  useEffect(() => {
    const fetchAmount = async () => {
      if (!questionId) {
        console.error("No se proporcionó un questionId.");
        return;
      }
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No se encontró un token. Usuario no autenticado.");
          return;
        }
        console.log("Obteniendo el monto para la pregunta con ID:", questionId);
        const response = await axios.get(
          `https://api.master-bikas.com/api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Respuesta del servidor para fetchAmount:", response.data);
        if (response.status === 200 && response.data.amount) {
          setAmount(response.data.amount);
        } else {
          console.error(
            "La respuesta no contiene el campo amount o tiene un estado inesperado."
          );
        }
      } catch (error) {
        console.error("Error al obtener el monto de la pregunta:", error);
      }
    };

    fetchAmount();
  }, [questionId]);

  useEffect(() => {
    let countdown;
    let statusCheckInterval;

    if (paymentConfirmed) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev > 1) {
            return prev - 1;
          } else {
            clearInterval(countdown);
            setShowWhatsAppButton(true);
            return 0;
          }
        });
      }, 1000);

      statusCheckInterval = setInterval(() => {
        checkPaymentStatus();
      }, 10000);
    }

    return () => {
      clearInterval(countdown);
      clearInterval(statusCheckInterval);
    };
  }, [paymentConfirmed]);

  const redirectToWhatsApp = () => {
    const phoneNumber = "+51921346549";
    const message = encodeURIComponent(
      "Hola, necesito ayuda para confirmar mi pago realizado por Yape. ¿Podrían ayudarme?"
    );
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  const handlePayment = async () => {
    if (!selectedMethod) {
      alert("Por favor, selecciona un método de pago.");
      return;
    }

    if (!questionId) {
      alert("Por favor, selecciona una pregunta.");
      console.error("Question ID es inválido:", questionId);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("No se encontró un token. Por favor, inicia sesión nuevamente.");
        return;
      }
      console.log("Enviando pago con los siguientes datos:");
      console.log("Método de pago:", selectedMethod);
      console.log("Pregunta ID enviada:", questionId);
      console.log("Token:", token);

      const response = await axios.post(
        "https://api.master-bikas.com/api/payments",
        {
          amount,
          payment_method: selectedMethod,
          currency: "PEN",
          description: `Pago por acceso a contenido con ${selectedMethod}`,
          question_id: questionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setPaymentConfirmed(true);
        alert("Pago realizado. Espera la confirmación.");
      }
    } catch (error) {
      console.error(
        "Error al realizar el pago:",
        error.response?.data || error.message
      );
      alert("Hubo un problema al procesar tu pago.");
    }
  };

  const handleMethodSelect = (method) => {
    setSelectedMethod(method);
  };

  const checkPaymentStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.master-bikas.com/api/check-payment-status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "confirmado") {
        const { courseId, topicId, questionId } = response.data;

        if (!courseId || !topicId || !questionId) {
          console.error(
            "Los valores courseId, topicId o questionId son inválidos:",
            { courseId, topicId, questionId }
          );
          return;
        }

        console.log("Redirigiendo a la pregunta desbloqueada...");
        navigate(
          `/course/${courseId}/topic/${topicId}/questions/${questionId}`
        );

        alert("Pago confirmado. Puntos asignados correctamente.");
      } else {
        console.log("El pago no ha sido confirmado todavía.");
      }
    } catch (error) {
      console.error("Error al verificar el estado del pago", error);
      alert("Hubo un problema al verificar el estado del pago.");
    }
  };

  return (
    <Box sx={{ bgcolor: "#FEFEFE", minHeight: "100vh" }}>
      <Navbar />
      <Container>
        <Grid container spacing={4} sx={{ py: 4 }}>
          {/* Columna 1: Instrucciones */}
          <Grid item xs={12} md={4}>
            <ImportantInstructions />
          </Grid>

          {/* Columna 2: Pago con Yape o Plin */}
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
              PAGA CON YAPE O PLIN
            </Typography>

            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mb: 4 }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleMethodSelect("YAPE")}
                sx={{
                  bgcolor: selectedMethod === "YAPE" ? "#0cc0df" : "#f5f5f5",
                  color: "black",
                  "&:hover": {
                    bgcolor: "#0a9cb0",
                  },
                }}
              >
                Pagar con YAPE
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleMethodSelect("PLIN")}
                sx={{
                  bgcolor: selectedMethod === "PLIN" ? "#0cc0df" : "#f5f5f5",
                  color: "black",
                  "&:hover": {
                    bgcolor: "#0a9cb0",
                  },
                }}
              >
                Pagar con PLIN
              </Button>
            </Box>

            {selectedMethod && (
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={selectedMethod === "YAPE" ? QRCode : QRCode2}
                  alt={`Código QR ${selectedMethod}`}
                  style={{ width: 180, height: 180 }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Escanea el código para pagar con {selectedMethod}
                </Typography>
              </Box>
            )}

            {amount && (
              <Typography variant="h6" sx={{ mt: 2 }}>
                Monto a pagar: S/ {amount}
              </Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
              sx={{ mt: 4 }}
              disabled={paymentConfirmed}
            >
              Pago realizado
            </Button>

            {paymentConfirmed && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Espera {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}{" "}
                minutos para la confirmación.
              </Typography>
            )}

            {showWhatsAppButton && (
              <button
                onClick={redirectToWhatsApp}
                style={{
                  backgroundColor: "#25D366",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Contactar por WhatsApp
              </button>
            )}
          </Grid>

          {/* Columna 3: Pasos para realizar el pago */}
          <Grid item xs={12} md={4}>
            <PaymentSteps />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default PagoYape;
