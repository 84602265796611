import Navbar2 from "./Navbar2";
import PaymentPanel from "./PaymentPanel";

const AdminPanel = () => {
 

  return (
    <div className="flex flex-col w-full min-h-screen">
      <Navbar2 />
      <main className="flex-1 p-4 bg-[#FCFBFC]" style={{ marginTop: "50px" }}>
        <PaymentPanel/>
      </main>
    </div>
  );
};

export default AdminPanel;
